<template>
  <div id="modal-customizado-pontuam">
    <div class="modal-bg">
      <div class="modal-fechar" @click.exact="fecharModal"></div>
      <div class="modal-conteudo">
        <div class="conteudo-tabela conteudo-interno">
          <h2>Adicionar regulamento</h2>
          <section class="container-tabela">
            <ckeditor v-model="dataText" :editor="editor" @ready="onReady"></ckeditor>
          </section>
          <div class="container-btns">
            <button class="btn btn-laranja" @click="salvar">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
export default {
  name: "ModalPromocaoRegulamento",
  data() {
    return {
      editor: DecoupledEditor,
      dataText: this.$store.state.promocoes.promocaoAceitaRegulamento,
    };
  },

  methods: {
    fecharModal() {
      this.$store.commit("SET_MODAL_CUSTOMIZADO", "");
    },
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    salvar() {
      this.$store.commit("promocoes/SET_PROMOCAO_ACEITA_REGULAMENTO", this.dataText);
      this.fecharModal();
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>
<style lang="scss">
#modal-customizado-pontuam {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  display: flex;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  padding: 50px 25px 70px 25px;

  @media (max-width: 800px) {
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .modal-bg {
    position: relative;
    z-index: 1;
    margin: auto;

    @media (max-width: 800px) {
      height: 100%;
    }

    &:before {
      content: "";
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
    }

    .modal-fechar {
      position: absolute;
      z-index: 1;
      top: 8px;
      right: 10px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        transform: scale(1.1);
      }

      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 4px;
        content: "";
        border-radius: 20px;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    .modal-conteudo {
      position: relative;
      width: 100%;

      @media (max-width: 800px) {
        height: 100%;
      }
    }
  }

  .conteudo-vazio {
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 20px;
    }
  }

  .conteudo-tabela {
    position: relative;
    box-sizing: border-box;
    width: 100vw;
    border-radius: 15px;
    max-width: 1110px;
    height: auto;
    max-height: 400px;
    padding: 30px 20px 50px 20px;
    text-align: center;
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.3);

    h2 {
      font-size: 35px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    @media (max-width: 800px) {
      min-height: 0px;
      width: 100vw;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
    }
  }

  .container-tabela {
    overflow: auto;
    height: 240px;

    @media (max-width: 800px) {
      height: calc(100% - 150px);
    }
  }

  .tabela-informacoes {
    width: 100%;
    margin-left: 0;
    height: 100%;

    .tabela-linha-informacoes {
      min-height: 60px;

      .tabela-linha-btns-incluir,
      input[type="checkbox"] {
        cursor: pointer;
      }
    }
  }

  .tabela-linha-btns {
    min-width: 50px;
    max-width: 50px;
  }

  .formulario-item {
    margin-bottom: 0;
    padding: 0;

    input {
      text-align: center;
    }
  }
}
</style>
